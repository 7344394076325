<template>
  <div class='body'>
    <!-- nav -->
    <Nav></Nav>
    <!-- banner -->
    <div class="banner" style="margin-bottom:12px">
      <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
        <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
          <base-img class="banner-img" :src="img.url"></base-img>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 新闻中心 -->
    <div class="news-center page-content">
      <div class='card'>
        <div class="news-banner-box">
          <div class="common-title-box">
            <div class="common-title">图片新闻</div>
            <a href="javascript:;" @click='$router.push("/news")' class="common-more">MORE +</a>
            
          </div>
          <div class="news-banner swiper-container">
            <swiper v-if="pageData.newsImg && pageData.newsImg.length" ref="newsSwiper" :options="newsSwiperOptions">
              <swiper-slide class="news-slider" v-for="item in pageData.newsImg" :key="item.id">
                <base-img class="news-swiper-img" :src="item.url"></base-img>
                <div class="news-title">{{ item.title }}</div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
      <div class='card' style='width: calc( 1280px - 272px - 280px - 16px );margin:0 8px'>
        <div class="news-list-box">
          <div class="common-title-box">
            <div class="common-title">集团新闻</div>
            <a href="javascript:;" @click='$router.push("/news")' class="common-more">MORE +</a>
            
          </div>
          <div class="news-list">
            <div v-if="pageData.newsList && pageData.newsList.length">
              <div class="news-item flex-a-c flex-j-b" v-for="item in newsList" :key="item.essaySourceId" @click="$router.push('/news/detail/'+item.essaySourceId)">
                <div class="news-name">{{ item.title }}</div>
                <div class="news-date">{{ item.releaseDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="news-video-box">
        <video controls poster="http://static.qingxinjituan.com/images/yangjiabu.jpg" src="http://static.qingxinjituan.com/video/yangjiabu.mp4">
          <p>
            Your browser doesn't support HTML5 video. Here is a
            <a href="http://static.qingxinjituan.com/video/yangjiabu.mp4">link to the video</a>
            instead.
          </p>
        </video>
        <div class="video-title-box">
          <div class="video-title">
            杨家埠文化创意梦想庭院
          </div>
        </div>
      </div>
    </div>
    <!-- 公司介绍 -->
    <div class="company-intro page-content card">
      <div class="common-title-box">
        <div class="common-title">集团简介</div>
      </div>
      <div style="display: flex;">
        <div class="company-intro-text" style="margin-right: 24px;">
          <div class="company-banner" v-if="
                pageData.companyIntro &&
                  pageData.companyIntro.length &&
                  pageData.companyIntro[0].img.length
              " :style="{
                backgroundImage: `url(${baseUrl +
                  pageData.companyIntro[0].img[0].url})`,
              }">
            <h1 v-if="
                  pageData.companyIntro &&
                    pageData.companyIntro.length &&
                    pageData.companyIntro[0].name
                ">
              {{ pageData.companyIntro[0].name }}
            </h1>
            <h3 v-if="
                  pageData.companyIntro &&
                    pageData.companyIntro.length &&
                    pageData.companyIntro[0].phone
                ">
              联系电话：{{ pageData.companyIntro[0].phone }}
            </h3>
          </div>
          <div class="article-content-box" v-if="pageData.companyIntro &&
                    pageData.companyIntro.length &&pageData.companyIntro[0].content" v-html="pageData.companyIntro[0].content"></div>
        </div>
        <div class="company-module">
          <div style="min-height:160px;margin-bottom: 16px;">
            <div class="company-module-item" v-if="pageData.businessSector && pageData.businessSector.length">
              <div class="company-module-name">业务板块</div>
              <div class="company-module-content flex-row-wrap" style="padding-bottom: 0">
                <router-link tag="div" :to='"/business_sector/"+index' class="company-module-content-img" v-for="(item,index) in pageData.businessSector" :key="item.id">
                  <base-img :src="item.url"></base-img>
                  <div class="title">{{ item.title }}</div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="company-module-item">
            <div class="company-module-name">下属公司</div>
            <div class="company-module-content">
              <div class="company-module-item-text" @click='goCompanyDetailPage(0)'>
                青欣一分公司
              </div>
              <div class="company-module-item-text" @click='goCompanyDetailPage(1)'>
                青欣二分公司
              </div>
              <div class="company-module-item-text" @click='goCompanyDetailPage(2)'>
                青欣三分公司
              </div>
              <div class="company-module-item-text" @click='goCompanyDetailPage(3)'>
                青欣四分公司
              </div>
              <div class="company-module-item-text" @click='goCompanyDetailPage(4)'>
                青欣五分公司
              </div>
              <div class="company-module-item-text" @click='goCompanyDetailPage(5)'>
                青欣六分公司
              </div>
              <div class="company-module-item-text" @click='goCompanyDetailPage(6)'>
                青欣七分公司
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 工程案例 -->
    <div class="case-box page-content card">
      <div class="common-title-box">
        <div class="common-title">工程案例</div>
      </div>
      <div style="min-height: 274px;">
        <a-tabs v-if="pageData.caseTab && pageData.caseTab.length" :default-active-key="0" :animated="false" style="text-align:center;">
          <a-tab-pane :tab="tab.name" class="case-img-box" v-for="(tab, index) in pageData.caseTab" :key="index">
            <div class="flex-row-wrap" v-viewer>
              <div v-for="(item,index) in tab.imgs" :key="item.id + index" class="case-img">
                <base-img :src="item.url"></base-img>
                <a-popover>
                  <template slot="content">
                    <p>{{item.content}}</p>
                  </template>
                  <div class='case-img-name'>{{item.content}}</div>
                </a-popover>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <!-- 公司荣誉 -->
    <!-- <div class="company-honor-box page-content">
      <div class="common-title-box">
        <div class="common-title">公司荣誉</div>
      </div>
      <edit-box style="margin-bottom:12px" :showEdit="editStatus" @change="handleChangeDesignData" multiple :max="8" :initSelectData="pageData.companyHonor" resourceType="showSelectImg" keyName="companyHonor">
        <div class="company-honor ">
          <div class="flex-row-wrap" v-viewer v-if="pageData.companyHonor && pageData.companyHonor.length">
            <base-img v-for="item in pageData.companyHonor" :key="item.id" :src="item.url" class="honor-img"></base-img>
          </div>
        </div>
      </edit-box>
    </div> -->
    <!-- 联系我们 -->
    <div class="about-us-box page-content card">
      <div class="common-title-box">
        <div class="common-title">联系我们</div>
      </div>
      <div class="flex-a-c">
        <div class="map-box" id="my-map">
          <a-button class='reload-map' icon='caret-left' type='primary' @click='mapCenter=[119.066608, 36.747287];zoom=15;'>返回</a-button>
          <el-amap v-if="showMap" vid="amapDemo" :plugin="plugin" :resizeEnable="true" :zoom="mapZoom" :center="mapCenter">
            <el-amap-marker :events="{
                click: () => {
                  openWindow()
                },
              }" :position="mapCenter"></el-amap-marker>
            <el-amap-info-window :events="{
                close: () => {
                  closeWindow()
                },
              }" :visible="showWindow" :position="mapCenter" :content="content">
            </el-amap-info-window>
          </el-amap>
        </div>
        <div class="form-box">
          <input type="text" v-model="formName" class="input input-name" placeholder="姓名" />
          <input type="text" v-model="formPhone" class="input input-phone" maxlength="11" placeholder="联系电话" />
          <input type="text" v-model="formEmail" class="input input-emial" maxlength="32" placeholder="邮箱" />
          <textarea class="textarea input-remark" v-model="formRemark" maxlength="200" placeholder="留言"></textarea>
          <div class="form-btn" @click='submitMsg'>提交</div>
        </div>
      </div>
    </div>
    <!-- 底部链接 -->
    <!-- <div class='footer-link-box'>
      <div class='page-content'>
        <div class='item'>
          <div>走进青欣</div>
          <ul>
            <li @click='$router("/company_intro")'>集团介绍</li>
            <li @click='$router("/company_culture")'>集团文化</li>
            <li @click='$router("/company_honor")'>公司荣誉</li>
            <li @click='$router("/company_qualification")'>资质证书</li>
          </ul>
        </div>
        <div class='item'>
          <div>工程案例</div>
          <ul>
            <li @click='$router("/project_case/0")'>市政工程</li>
            <li @click='$router("/project_case/1")'>公路工程</li>
            <li @click='$router("/project_case/2")'>小区景观</li>
            <li @click='$router("/project_case/3")'>校园绿化</li>
          </ul>
        </div>
        <div class='item'>
          <div>行业领域</div>
          <ul>
            <li @click='$router("/industry/0")'>市政园林工程施工</li>
            <li @click='$router("/industry/1")'>农业苗木</li>
            <li @click='$router("/industry/2")'>铭喆酒店</li>
            <li @click='$router("/industry/3")'>生态旅游</li>
            <li @click='$router("/industry/4")'>职业培训学校</li>
            <li @click='$router("/industry/5")'>立体花卉</li>
          </ul>
        </div>
      </div>
    </div> -->
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import { design, common } from '@/api'
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
export default {
  name: 'Home',
  components: {
    Nav,
    Footer,
  },
  data() {
    const self = this
    return {
      formName: '',
      formPhone: '',
      formEmail: '',
      formRemark: '',
      mapZoom: 15,
      mapCenter: [119.066608, 36.747287],
      baseUrl: process.env.VUE_APP_IMG,
      pageData: {},
      designStorageId: '',
      newsList: [],
      companyIntroDetail: {},
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      newsSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        loop: true,
      },
      content: `<div class='info-window'>
                  <div class='info-window-name'>青欣建设集团</div>
                  <div class='link info-window-phone flex-row'>电话：<a href='tel:0536-8915813'>0536-8915813</a></div>
                  <div class='link info-window-email flex-row'>邮箱：<a href='mailto:wfqingxin@126.com'>wfqingxin@126.com</a></div>
                  <div class='info-window-addr'>地址：潍坊市潍城区玄武街西首三里庄路往西一百米路北</div>
                </div>`,
      plugin: [
        {
          pName: 'Geolocation',
          enableHighAccuracy: true,
          events: {
            init(o) {
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.content = ` <div class='info-window'>
                  <div class='info-window-name'>青欣建设集团</div>
                  <div class='link info-window-phone flex-row'>电话：<a href='tel:0536-8915813'>0536-8915813</a></div>
                  <div class='link info-window-email flex-row'>邮箱：<a href='mailto:wfqingxin@126.com'>wfqingxin@126.com</a></div>
                  <div class='info-window-addr'>地址：潍坊市潍城区玄武街西首三里庄路往西一百米路北</div>
                </div> `
                  self.mapCenter = [119.066608, 36.747287]
                  self.mapZoom = 15
                }
              })
            },
          },
        },
      ],
      showWindow: true,
      showMap: false
    }
  },
  async mounted() {
    await this.getDesignData()
    this.showMap = true
  },
  methods: {
    async getNewsList() {
      const { data, msg, code } = await design.getArticleListByIds({
        essaySourceId: this.pageData.newsList
          .map((item) => {
            return item.id
          })
          .join(','),
      })
      if (code === '00000') {
        this.newsList = data
      } else {
        this.$message.error(msg || '获取新闻列表数据失败')
      }
    },
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'index' })

      if (code === '00000') {
        if (data) {
          console.log(JSON.parse(data.designData))
          this.designStorageId = data.designStorageId
          this.pageData = data.designData ? JSON.parse(data.designData) : {}
          if (this.pageData.newsList && this.pageData.newsList.length > 0) {
            this.getNewsList()
          }
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },

    openWindow() {
      this.showWindow = true
    },
    closeWindow() {
      this.showWindow = false
    },
    goCompanyDetailPage(id) {
      this.$router.push('/sub_company_detail/' + id)
    },
    async submitMsg() {
      if (!this.formName) {
        return this.$message.error('请填写您的姓名')
      }
      if (this.formName.length > 32) {
        return this.$message.error('请尝试简短一点的姓名')
      }
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.formPhone)) {
        return this.$message.error('请填写正确的手机号')
      }
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.formEmail
        )
      ) {
        return this.$message.error('请填写正确的邮箱地址')
      }
      const { code, msg } = await common.submitForm({
        name: this.formName,
        phone: this.formPhone,
        email: this.formEmail,
        remark: this.formRemark,
      })
      if (code === '00000') {
        this.$message.success('提交成功')
      } else {
        this.$message.error(msg || '提交失败')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 500px;
  .banner-img {
    width: 100%;
    height: 500px;
    ::v-deep img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

/* news  */
.news-center {
  display: flex;
  margin-bottom: 12px;
}
.news-banner-box {
  width: 240px;
}
.news-banner {
  width: 240px;
  height: 200px;
  ::v-deep .swiper-pagination {
    bottom: 0px !important;
    text-align: right;
    color: #fff;
    padding-right: 8px;
    font-size: 12px;
    line-height: 32px;
    box-sizing: border-box;
  }
  .news-slider {
    position: relative;
    .news-swiper-img {
      width: 240px;
      height: 200px;
      object-fit: cover;
      ::v-deep img {
        width: 240px;
        height: 200px;
        object-fit: cover;
      }
    }
    .news-title {
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 32px;
      z-index: 10;
      background: rgba(0, 0, 0, 0.7);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
      color: #fff;
      padding: 0 40px 0 8px;
    }
  }
}
.news-list-box .news-list {
  padding: 4px 0;
  min-height: 200px;
  width: 100%;
  box-sizing: border-box;
}
.news-list-box .news-list .news-item {
  justify-content: space-between;
  cursor: pointer;
  height: 24px;
}
.news-list-box .news-list .news-item .news-name {
  font-size: 14px;
  width: calc(100% - 100px);
  color: #2b2b2b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.news-list-box .news-list .news-item .news-name:hover {
  color: #38b352;
}
.news-list-box .news-list .news-item .news-date {
  width: 80px;
  font-size: 12px;
  color: #666;
  text-align: right;
}

.news-video-box {
  width: 280px;
  flex-shrink: 0;
  /* background: #38b352; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}
.news-video-box video {
  width: 280px;
  height: 218px;
  object-fit: cover;
  border: none;
  outline: none;
}
.news-video-box .video-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  font-size: 14px;
  color: #fff;
  line-height: 24px;
}
.video-title-box {
  padding: 12px;
  background: #38b352;
  width: 100%;
}

/* 公司介绍 */
.company-intro {
  margin-bottom: 12px;
}
.company-banner {
  background-repeat: no-repeat;
  width: 100%;
  height: 140px;
  background-size: cover;
  background-position: center center;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-indent: 2em;
}
.company-banner h1 {
  margin: 0 0 12px;
  text-shadow: black 0.1em 0.1em 0.2em;
  color: #fff;
}
.company-banner h3 {
  margin: 0;
  text-shadow: black 0.1em 0.1em 0.2em;
  color: #fff;
}

.company-intro-text {
  font-size: 14px;
  line-height: 24px;
  width: calc(1280px - 256px);
  // flex: 1;
}
.company-intro-text p {
  margin: 0;
  color: #2b2b2b;
}
.company-module {
  width: 200px;
  flex-shrink: 0;
}
.company-module-item {
  border: 1px solid #38b352;
  border-radius: 4px;
}
.company-module-item:last-child {
  margin: 0;
}
.company-module-name {
  background-color: #38b352;
  color: #fff;
  font-size: 14px;
  padding: 0 16px;
  line-height: 36px;
}
.company-module-content {
  padding: 12px;
}
.company-module-item-text {
  font-size: 12px;
  color: #2b2b2b;
  line-height: 20px;
  position: relative;
  text-indent: 12px;
  cursor: pointer;
}
.company-module-item-text:hover {
  color: #38b352;
}
.company-module-item-text::after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 4px;
  height: 4px;
  background-color: #38b352;
  border-radius: 2px;
}
.company-module-content-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc((100% - 8px) / 2);
  margin-right: 8px;
  margin-bottom: 12px;
  flex-shrink: 0;
  cursor: pointer;
}
.company-module-content-img:nth-of-type(2n) {
  margin-right: 0;
}
.company-module-content-img {
  ::v-deep img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    background-color: #ddd;
  }
  .title {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.company-module-content-img a {
  font-size: 12px;
  color: #2b2b2b;
  margin-top: 8px;
}
.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.case-box {
  margin-bottom: 12px;
}
.case-img-box {
  padding-top: 32px;
}
.case-img-box .flex-row-wrap .case-img {
  width: calc((100% - 64px) / 5);
  object-fit: cover;
  display: block;
  margin-right: 16px;
  margin-bottom: 12px;
  cursor: pointer;
}
.case-img-box .flex-row-wrap .case-img:nth-of-type(5n) {
  margin-right: 0;
}
.case-img-box .flex-row-wrap .case-img:hover ::v-deep img {
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.case-img-box .flex-row-wrap .case-img ::v-deep .base-img {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.case-img-box .flex-row-wrap .case-img ::v-deep img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 1s;
}
::v-deep .ant-tabs-tab {
  font-size: 16px;
}
::v-deep .ant-tabs-nav .ant-tabs-tab-active {
  color: #38b352;
}
::v-deep .ant-tabs-tab:hover {
  color: #38b352;
}
::v-deep .ant-tabs-ink-bar {
  background-color: #38b352;
}
::v-deep .ant-tabs-bar {
  border: none;
  margin-bottom: 8px;
}

/* 荣誉 */
.company-honor-box {
  margin-bottom: 12px;
}
.company-honor {
  min-height: 160px;
}
.company-honor .honor-img {
  width: calc((100% - 48px) / 4);
  height: 200px;
  margin-right: 16px;
  margin-bottom: 12px;
  ::v-deep img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    cursor: pointer;
    transition: 1s;
  }
}
.company-honor .honor-img:nth-of-type(4n) {
  margin-right: 0;
}
.company-honor .honor-img ::v-deep img:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.about-us-box {
  margin-bottom: 12px;
  justify-content: space-between;
}
#my-map {
  width: 944px;
  height: 300px;
  flex-shrink: 0;
  background: #eee;
  position: relative;
}
.form-box {
  margin-left: 16px;
  width: 320px;
}

.form-box .input {
  display: block;
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  outline-color: #38b352;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
.form-box .input:last-child {
  margin-bottom: 0;
}
.form-box .textarea {
  width: 100%;
  display: block;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 4px;
  resize: none;
  height: 80px;
  font-size: 14px;
  margin-bottom: 16px;
  box-sizing: border-box;
  outline-color: #38b352;
}
.form-box .form-btn {
  background-color: #38b352;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
::v-deep .info-window {
  padding: 16px 12px;
  width: 380px;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  // border: 1px solid #999;
  // &:before,
  // &:after {
  //   position: absolute;
  //   content: '';
  //   border: 10px solid;
  // }
  // &:before {
  //   left: 177px;
  //   bottom: -21px;
  //   border-color: #999 transparent transparent transparent;
  // }
  // &:after {
  //   border-color: #fff transparent transparent transparent;
  //   left: 177px;
  //   bottom: -19px;
  // }
  .info-window-name {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 12px;
  }
  .info-window-phone {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  .info-window-email {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }

  .info-window-addr {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  .link a:hover {
    color: #2a8ff3;
  }
  .info-window-btn {
    cursor: pointer;
    width: 4em;
    font-size: 14px;
    color: #fff;
    background-color: #2a8ff3;
    line-height: 32px;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 14px;
  }
}

.reload-map {
  position: absolute;
  left: 8px;
  top: 20px;
  z-index: 1;
  cursor: pointer;
  background: #2a8ff3;
  border-color: #2a8ff3;
}
.case-img-name {
  font-size: 16px;
  line-height: 32px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.footer-link-box {
  background: #e2e2e2;
  padding: 32px 0;
  .page-content {
    display: flex;
    justify-content: center;
    .item {
      text-align: center;
      &:last-child ul {
        border: none;
      }
      div {
        color: #38b352;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 16px;
      }
      ul {
        margin: 0;
        padding: 0;
        border-right: 1px dashed #666;
        padding: 0 88px;
        height: calc(100% - 46px);
        li {
          list-style: none;
          line-height: 32px;
          cursor: pointer;
          &:hover {
            color: #38b352;
          }
        }
      }
    }
  }
}
</style>
